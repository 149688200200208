import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './CampaignDetails.styles';
import { Box } from '@mui/material';
import { TopSection } from './TopSection/TopSection';
import { MainContent } from './MainContent/MainContent';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getCampaignById,
  getCampaignCountData,
  getCampaignInvitations,
  getCampaignVideos,
  getInvitesCounts,
  resetCurrentRewardCampaign,
} from '../../../store/slices/campaignsSlice';
import { useParams } from 'react-router-dom';
import {
  MAX_STEP_INDEX,
  useQuickStartLaunchPad,
} from '../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { getMe } from '../../../store/slices/meSlice';
import { resetNewCampaign } from '../../../store/slices/createCampaignSlice';
import { UwmLaunchModal } from '../../../common/components/UwmLaunchModal/UwmLaunchModal';
import { useClientType } from '../../../services/hooks/useClientType';
import { useModal } from '../../../hooks/useModal';
import { ImportFileModal } from '../../rewards/CampaignSummary/Modals/ImportFileModal';
import { AddIndividualCustomerModal } from '../../rewards/CampaignSummary/Modals/AddIndividualCustomerModal';
import { AddAndUploadModal } from '../../rewards/CampaignSummary/Modals/AddAndUploadModal/AddAndUploadModal';
import { getVideos } from '../../../store/slices/videosSlice';
import { QSStep } from '../../../common/components/AnimatedQsMarker/AnimatedQsMarker';
import { QuickStartStrings } from '../../../common/localization/en';
import { getIsNullReward, removeFilterFromUrl } from '../../../services/utilities';
import { VideoStatusType } from '../../../api/models/searchFilterModels';

export const CampaignDetails: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id: campaignId } = useParams<{ id: string }>();
  const {
    isStarted: isQsStarted,
    moveToNextTopicStep,
    moveToNextItem,
    currentTopicStep,
  } = useQuickStartLaunchPad();
  const { isUwmClient } = useClientType();

  const { id: meId } = useTypedSelector((state) => state.me);
  const { currentCampaign, uwmRedirectCampaign } = useTypedSelector((state) => state.campaigns);
  const { isLoading: videosLoading, items } = useTypedSelector((state) => state.videos);
  const initialState = items.map((video) => ({
    id: video.id,
    thumbnailUrl: video.thumbnailUrl,
    url: video.url,
    displayName: video.userName,
    checked: video.status === VideoStatusType.Unpublished,
  }));

  const [videosToReview, setVideosToReview] = useState(initialState);
  const [isInitialRun, setIsInitialRun] = useState(false);
  const {
    isLaunchpadVisible,
    isFinished,
    startLaunchPadFlow,
    isStarted,
    isUpdating,
    isLoading: isQSlaunchpadPorpertiesLoading,
    currentTargetIndex,
  } = useQuickStartLaunchPad();
  const [addedUserName, setAddedUserName] = useState('');
  const [uploadVideoOption, setUploadVideoOption] = useState('No');
  const { id } = useTypedSelector((state) => state.venue.venue);
  // const {
  //   campaignUnsentInvitations: { totalItems: unsentInvitesCount },
  //   lastSendBatchStats,
  // } = useTypedSelector((state) => state.campaigns);

  const {
    isOpen: isUwmLaunchModalOpen,
    open: openUwmLaunchModal,
    close: closeUwmLaunchModal,
  } = useModal();

  const {
    isOpen: isImportFileModalOpen,
    open: openImportFileModal,
    close: closeImportFileModal,
  } = useModal(false);

  const {
    isOpen: isAddCustomerModalOpen,
    open: openAddCustomerModal,
    close: closeAddCustomerModal,
  } = useModal(false);

  const { isOpen: isUploadModalOpen, open: openUploadModal, close: closeUploadModal } = useModal(
    false,
  );

  useEffect(() => {
    if (items.length !== 0 && !isInitialRun) {
      setVideosToReview(
        items
          .map((video) => ({
            id: video.id,
            thumbnailUrl: video.thumbnailUrl,
            url: removeFilterFromUrl(video.url),
            displayName: video.userName,
            checked: video.status === VideoStatusType.Unpublished,
          }))
          .filter((video) => video.checked),
      );
      setIsInitialRun(true);
    }
  }, [items, isInitialRun]);

  useEffect(() => {
    return () => {
      dispatch(resetNewCampaign());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const openUwmLaunchModalandResetLocalStorage = () => {
      openUwmLaunchModal();
      localStorage.setItem('showUwmLaunchModal', 'false');
    };
    if (
      isUwmClient &&
      uwmRedirectCampaign?.id === currentCampaign?.id &&
      localStorage.getItem('showUwmLaunchModal') === 'true'
    ) {
      if (!isStarted && !!currentTargetIndex) {
        openUwmLaunchModalandResetLocalStorage();
      } else if (
        !isUpdating &&
        !isStarted &&
        !currentTargetIndex &&
        !isQSlaunchpadPorpertiesLoading
      ) {
        (async () => {
          return await startLaunchPadFlow();
        })();
      }
    }
  }, [
    isUwmClient,
    currentCampaign,
    uwmRedirectCampaign?.id,
    startLaunchPadFlow,
    isStarted,
    isUpdating,
    isQSlaunchpadPorpertiesLoading,
    currentTargetIndex,
    openUwmLaunchModal,
  ]);

  useEffect(() => {
    dispatch(getVideos({ accountId: id, pageable: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaignCountData({ campaignId: campaignId }));
      dispatch(getCampaignById(campaignId));
      dispatch(
        getCampaignVideos({
          id: campaignId,
          pageable: {
            page: 0,
            size: 100000,
            sort: ['asc'],
          },
        }),
      );
    }

    return () => {
      dispatch(resetCurrentRewardCampaign());
    };
  }, [campaignId, dispatch]);

  useEffect(() => {
    dispatch(
      getMe({
        id: meId,
      }),
    );
  }, [dispatch, meId]);

  useEffect(() => {
    if (!isQsStarted || isFinished || currentTargetIndex === MAX_STEP_INDEX || isLaunchpadVisible)
      return;

    const elementsToBlock: NodeListOf<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    > = document.querySelectorAll('button, a, [data-qs-button="false"], div[role="button"]');

    elementsToBlock.forEach((element) => {
      if (element.dataset.qsButton === 'true') return;

      element.onmouseover = () => {
        element.style.cursor = 'not-allowed';
      };

      if (element instanceof HTMLAnchorElement) {
        element.onclick = (event) => {
          event.preventDefault();
        };
        return;
      }

      if (element instanceof HTMLButtonElement) {
        element.disabled = true;
        return;
      }
    });
  }, [isQsStarted, currentTopicStep, isFinished, currentTargetIndex, isLaunchpadVisible]);

  const qsSteps: QSStep[] = [
    {
      title: QuickStartStrings.CampaignStep1Title,
      text: QuickStartStrings.CampaignStep1Text,
      onClick: moveToNextTopicStep,
      width: '260px',
      hideMarkerOnClick: true,
    },
    {
      title: QuickStartStrings.CampaignStep2Title,
      text: QuickStartStrings.CampaignStep2Text,
      onClick: moveToNextTopicStep,
      width: '286px',
      translate: '150px',
    },
    {
      title: QuickStartStrings.CampaignStep3Title,
      text: QuickStartStrings.CampaignStep3Text,
      onClick: moveToNextTopicStep,
      width: '286px',
      translate: '362px',
    },
    {
      title: QuickStartStrings.CampaignStep4Title,
      text: QuickStartStrings.CampaignStep4Text,
      onClick: moveToNextTopicStep,
      width: '286px',
      translate: '574px',
    },
    {
      title: getIsNullReward(currentCampaign?.campaignType)
        ? QuickStartStrings.CampaignStep5NoRewardTitle
        : QuickStartStrings.CampaignStep5Title,
      text: getIsNullReward(currentCampaign?.campaignType)
        ? QuickStartStrings.CampaignStep5NoRewardText
        : QuickStartStrings.CampaignStep5Text,
      onClick: () => {
        moveToNextItem({ skipTracking: true });
      },
      width: '286px',
      isLastStep: true,
      translate: '767px',
    },
    // {
    //   title: QuickStartStrings.CampaignStep6Title,
    //   text: QuickStartStrings.CampaignStep6Text,
    //   onClick: () => {
    //     moveToNextItem({ skipTracking: true });
    //   },
    //   width: '286px',
    //   isLastStep: true,
    //   translate: '985px',
    // },
  ];

  return (
    <Box className={classes.container}>
      {currentCampaign &&
      !videosLoading &&
      !isLaunchpadVisible &&
      !isFinished &&
      videosToReview.length ? (
        <UwmLaunchModal
          isOpen={isUwmLaunchModalOpen}
          onClose={() => {
            closeUwmLaunchModal();
          }}
          campaign={currentCampaign}
          openImportModal={openImportFileModal}
          openIndividualModal={openAddCustomerModal}
        />
      ) : (
        <div></div>
      )}
      <ImportFileModal
        campaignId={currentCampaign?.id!}
        isOpen={isImportFileModalOpen}
        onClose={closeImportFileModal}
        onFinish={() => {
          dispatch(getInvitesCounts({ campaignId }));
        }}
      />

      <AddIndividualCustomerModal
        campaignId={currentCampaign?.id!}
        isOpen={isAddCustomerModalOpen}
        onClose={() => {
          closeAddCustomerModal();
          setUploadVideoOption('No');
          if (uploadVideoOption === 'Yes') {
            openUploadModal();
          }
        }}
        uploadVideoOption={uploadVideoOption}
        onUploadVideoOptionChange={(value) => {
          setUploadVideoOption(value);
        }}
        onCustomerAdded={(name) => {
          setAddedUserName(name);
          dispatch(getCampaignCountData({ campaignId }));
          dispatch(getCampaignInvitations({ campaignId }));
          dispatch(getInvitesCounts({ campaignId }));
        }}
        withInvite={true}
      />
      {currentCampaign && (
        <AddAndUploadModal
          campaignId={currentCampaign.id}
          isOpen={isUploadModalOpen}
          onClose={closeUploadModal}
          name={addedUserName}
          selectedCampaign={currentCampaign}
          onUploaded={() => {
            dispatch(getCampaignCountData({ campaignId }));
          }}
        />
      )}
      <TopSection
        qsSteps={qsSteps.map((item, index) => ({
          ...item,
          translate: item.translate,
          // unsentInvitesCount || lastSendBatchStats?.sentAt || index === 0
          //   ? item.translate
          // : `${Number(getOnlyNumbers(item.translate || '')) + 72}px`,
        }))}
      />
      <MainContent />
    </Box>
  );
};
