import React, { FC } from 'react';
import { useStyles } from './RewardInfo.styles';
import { Box, Button, Typography } from '@mui/material';
import { useTypedSelector } from '../../../../../store';
import { CampaignSummaryRewardStrings } from '../../../../../common/localization/en';
import { RewardFulfillmentMethod } from '../../../../../api/models/rewardFulfillment';
import { useModal } from '../../../../../hooks/useModal';
import RewardModal from '../../RewardModal/RewardModal';
import { useQuickStartLaunchPad } from '../../../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import AnimatedQsMarker, {
  QSStep,
} from '../../../../../common/components/AnimatedQsMarker/AnimatedQsMarker';
import { getIsNullReward } from '../../../../../services/utilities';
import { CampaignTypes } from '../../../../../api/models/campaigns';

interface Props {
  qsSteps: QSStep[];
}

const RewardInfo: FC<Props> = ({ qsSteps }) => {
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const classes = useStyles();
  const { isQSMarkerVisible, currentTopicStep } = useQuickStartLaunchPad();
  const isActiveCampagn = !currentCampaign?.endedAt;

  const isNullReward = getIsNullReward(currentCampaign?.campaignType);

  const getInfoText = () => {
    if (!currentCampaign) return;

    if (isNullReward && !isMonthlyGiveAway) {
      return currentCampaign?.endedAt
        ? CampaignSummaryRewardStrings.NoRewardInfoEndedCampaign
        : CampaignSummaryRewardStrings.NoRewardInfo;
    }

    if (currentCampaign.fulfillmentConfig?.method === RewardFulfillmentMethod.MANUAL_SMS) {
      return CampaignSummaryRewardStrings.RewardManualInfo;
    }

    if (currentCampaign.isMultiFulfillment) {
      return CampaignSummaryRewardStrings.RewardAutoEveryInfo;
    }

    return CampaignSummaryRewardStrings.RewardAutoFirstInfo;
  };

  const isMonthlyGiveAway = currentCampaign?.campaignType === CampaignTypes.MONTHLY_GIVEAWAY;
  const isNoReward = currentCampaign?.campaignType === CampaignTypes.NO_REWARD;

  const CTAtexts: Record<CampaignTypes, string> = {
    [CampaignTypes.CUSTOM_REWARD]: CampaignSummaryRewardStrings.Customize,
    [CampaignTypes.NO_REWARD]: CampaignSummaryRewardStrings.AddReward,
    [CampaignTypes.MONTHLY_GIVEAWAY]: CampaignSummaryRewardStrings.SelectCustom,
  };

  const {
    isOpen: isRewardModalOpen,
    open: openRewardModalOpen,
    close: closeRewardModalOpen,
  } = useModal(false);

  return (
    <>
      {currentCampaign && (
        <RewardModal
          isOpen={isRewardModalOpen}
          onClose={closeRewardModalOpen}
          campaign={currentCampaign}
        />
      )}
      <Box
        className={classes.container}
        style={{
          minHeight: isActiveCampagn ? '156px' : '120px',
        }}
      >
        {isQSMarkerVisible && currentTopicStep === 0 && (
          <Box className={classes.qsMarkerWrapper}>
            <AnimatedQsMarker arrowPosition="right" steps={qsSteps} direction="column" />
          </Box>
        )}
        <Typography className={classes.title}>
          {isNoReward
            ? CampaignSummaryRewardStrings.NoRewardTitle
            : CampaignSummaryRewardStrings.RewardTitle}
        </Typography>
        {!isNoReward && (
          <Typography className={classes.rewardName}>{currentCampaign?.rewardTitle}</Typography>
        )}
        {!isMonthlyGiveAway ? (
          <Typography className={classes.info}>{getInfoText()}</Typography>
        ) : (
          <Typography className={classes.info}>
            {CampaignSummaryRewardStrings.MonthlyGiveawayDescription}{' '}
            <a
              href="https://www.socialvenu.com/"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0084FF' }}
            >
              {CampaignSummaryRewardStrings.LearnMore}{' '}
            </a>
          </Typography>
        )}

        {currentCampaign && !currentCampaign?.endedAt && (
          <Box sx={{ mt: 'auto' }}>
            <Button
              variant={isMonthlyGiveAway ? 'outlined' : 'contained'}
              onClick={openRewardModalOpen}
              style={isMonthlyGiveAway ? { border: 'none' } : {}}
            >
              {CTAtexts[currentCampaign.campaignType]}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RewardInfo;
