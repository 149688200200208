import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from '../CreatorsSearchTags.styles';
import {
  CreatorAttributeName,
  CreatorFilterAttribute,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { SearchTagsStrings } from '../../../localization/en';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import MenuContainer from '../../MenuContainer/MenuContainer';
import {
  removeCreatorOptedInFilter,
  removeCreatorVerificationFilter,
  setActiveCreatorFilterAttributes,
  updateCreatorVerificationFilter,
} from '../../../../store/slices/searchFilterSlice';
import { VerifiedIcon } from '../../../../pages/content/tableSection/contentTable/UserPhoneStatusIcon';
import { UnverifiedIcon } from '../../../assets/UnverifiedIcon';
import { OptedOutIcon } from '../../../assets/OptedOutIcon';
import { getUsersBySearchFilter } from '../../../../store/slices/heroesSlice';

const VerificationTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { activeCreatorFilterAttributes, search } = useTypedSelector((state) => state.searchFilter);
  const { page, size, sort } = useTypedSelector((state) => state.videos);
  const {
    venue: { id },
  } = useTypedSelector((state) => state.venue);

  const [verificationAnchorEl, setVerificationAnchorEl] = useState<null | HTMLElement>(null);
  const isVerificationMenuOpen = Boolean(verificationAnchorEl);

  const handleVerificationMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setVerificationAnchorEl(event.currentTarget);
  };

  const handleVerificationMenuClose = () => {
    setVerificationAnchorEl(null);
  };

  const verificationMap: {
    name: string;
    value: SearchTagsStrings.Confirmed | SearchTagsStrings.UnConfirmed | SearchTagsStrings.OptedOut;
    icon: JSX.Element;
  }[] = [
    {
      name: 'Confirmed',
      value: SearchTagsStrings.Confirmed,
      icon: <VerifiedIcon />,
    },
    {
      name: 'Unconfirmed',
      value: SearchTagsStrings.UnConfirmed,
      icon: <UnverifiedIcon />,
    },
    {
      name: 'Opted-Out',
      value: SearchTagsStrings.OptedOut,
      icon: <OptedOutIcon />,
    },
  ];

  const verificationFilterIdx = activeCreatorFilterAttributes.findIndex(
    (item) => item.name === CreatorAttributeName.PhoneNumber,
  );

  const optedOutFilterIdx = activeCreatorFilterAttributes.findIndex(
    (item) => item.name === CreatorAttributeName.OptedIn,
  );

  const handleVerificationChange = (
    value: SearchTagsStrings.Confirmed | SearchTagsStrings.UnConfirmed | SearchTagsStrings.OptedOut,
  ) => {
    if (value === SearchTagsStrings.OptedOut) {
      if (verificationFilterIdx !== -1) {
        dispatch(removeCreatorVerificationFilter());
      }

      const filter: CreatorFilterAttribute[] =
        verificationFilterIdx !== -1
          ? [
              ...activeCreatorFilterAttributes.filter(
                (filter) => filter.name !== CreatorAttributeName.PhoneNumber,
              ),
              {
                name: CreatorAttributeName.OptedIn,
                operation: SearchOperationType.Equals,
                value: false,
              },
            ]
          : [
              ...activeCreatorFilterAttributes,
              {
                name: CreatorAttributeName.OptedIn,
                operation: SearchOperationType.Equals,
                value: false,
              },
            ];

      if (optedOutFilterIdx === -1) {
        dispatch(setActiveCreatorFilterAttributes(filter));

        dispatch(
          getUsersBySearchFilter({
            filter: filter,
            pageable: {
              page,
              size,
              sort,
            },
            venueId: id,
            search: search,
          }),
        );

        return;
      }

      return;
    }

    const boolValue = value === SearchTagsStrings.Confirmed ? true : false;

    if (verificationFilterIdx === -1) {
      if (optedOutFilterIdx !== -1) {
        dispatch(removeCreatorOptedInFilter());
      }

      const filter: CreatorFilterAttribute[] =
        optedOutFilterIdx !== -1
          ? [
              ...activeCreatorFilterAttributes.filter(
                (filter) => filter.name !== CreatorAttributeName.OptedIn,
              ),
              {
                name: CreatorAttributeName.PhoneNumber,
                operation: SearchOperationType.Exists,
                value: boolValue,
              },
            ]
          : [
              ...activeCreatorFilterAttributes,
              {
                name: CreatorAttributeName.PhoneNumber,
                operation: SearchOperationType.Exists,
                value: boolValue,
              },
            ];

      dispatch(setActiveCreatorFilterAttributes(filter));

      dispatch(
        getUsersBySearchFilter({
          filter: filter,
          pageable: {
            page,
            size,
            sort,
          },
          venueId: id,
          search: search,
        }),
      );

      return;
    }

    dispatch(updateCreatorVerificationFilter(boolValue));

    dispatch(
      getUsersBySearchFilter({
        filter: activeCreatorFilterAttributes.map((filter) =>
          filter.name === CreatorAttributeName.PhoneNumber
            ? { ...filter, value: boolValue }
            : filter,
        ),
        pageable: {
          page,
          size,
          sort,
        },
        venueId: id,
        search: search,
      }),
    );
  };

  const handleRemoveVerificationFilter = () => {
    if (optedOutFilterIdx !== -1) {
      dispatch(removeCreatorOptedInFilter());
      return;
    }

    dispatch(removeCreatorVerificationFilter());
  };

  const verificationFilterValue =
    verificationFilterIdx !== -1 &&
    activeCreatorFilterAttributes[verificationFilterIdx].value === true
      ? SearchTagsStrings.Confirmed
      : SearchTagsStrings.UnConfirmed;

  return (
    <>
      <Box
        className={`${styles.tag} ${
          (verificationFilterIdx !== -1 || optedOutFilterIdx !== -1) && styles.active
        }`}
        onClick={handleVerificationMenuOpen}
      >
        {SearchTagsStrings.Verification}:{' '}
        {verificationFilterIdx === -1 ? (
          optedOutFilterIdx !== -1 && SearchTagsStrings.OptedOut
        ) : (
          <Box
            sx={{
              marginLeft: '4px',
              display: 'flex',
            }}
          >
            {verificationFilterValue}
          </Box>
        )}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (verificationFilterIdx !== -1 || optedOutFilterIdx !== -1) {
              e.stopPropagation();
              handleRemoveVerificationFilter();
            }
          }}
        >
          {verificationFilterIdx !== -1 || optedOutFilterIdx !== -1 ? (
            <PurpleCloseIcon />
          ) : (
            <SmallArrowIcon />
          )}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={verificationAnchorEl}
        isOpen={isVerificationMenuOpen}
        onClose={handleVerificationMenuClose}
        padding="4px 0"
      >
        {verificationMap.map((verification) => (
          <Box
            key={verification.value}
            className={styles.verification}
            onClick={() => {
              handleVerificationChange(verification.value);
              handleVerificationMenuClose();
            }}
          >
            {verification.icon} {verification.name}
          </Box>
        ))}
      </MenuContainer>
    </>
  );
};

export default VerificationTag;
