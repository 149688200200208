const VIDEO_CONTENT_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For inspiring others with your videos, here’s your [Reward Name].
`;
const SHOWCASE_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For your inspiring & widely viewed videos, here’s your [Reward Name].
`;
const SHARING_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For your inspiring & popular videos, here’s your [Reward Name].
`;

const VIDEO_CONTENT_experiential = `Hey [Customer Name], 

Thank you for being a valued customer of [Company Name]!

For creating all of your engaging videos, here’s your [Reward Name].`;

const SHOWCASE_experiential = `Hey [Customer Name], 

Thank you for being a valued customer of [Company Name]!

For creating widely viewed & memorable videos, here’s your [Reward Name].`;

const SHARING_experiential = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For creating popular, engaging videos, here’s your [Reward Name].`;

const VIDEO_CONTENT_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For sharing your sense of humor in all your videos, here’s your [Reward Name].`;

const SHOWCASE_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For creating widely viewed & funny videos, here’s your [Reward Name].`;

const SHARING_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

For creating your popular & funny videos, here’s your [Reward Name].`;

const defaultInvite = `Hi [Customer Name], it's [Company Name]. Thank you for sharing your story with us! 

As a token of thanks, please enjoy [Reward Name]! Just contact us ASAP. 

We’re thrilled to have you as part of our family.

Keep inspiring!`;

const monthlyGiveawayDefault = `Hi [Customer Name], it's [Company Name]. Thank you for inspiring others with your video and for being a valued part of our family. 
If the video qualifies, you'll be entered in the $1000 Monthly Giveaway!`;

export const default_sms_reward: Record<string, string> = {
  VIDEO_CONTENT_inspirational,
  SHOWCASE_inspirational,
  SHARING_inspirational,

  VIDEO_CONTENT_experiential,
  SHOWCASE_experiential,
  SHARING_experiential,

  VIDEO_CONTENT_funny,
  SHOWCASE_funny,
  SHARING_funny,

  defaultInvite,
  monthlyGiveawayDefault,
};

export const default_sms_reward_TruBlu: Record<string, string> = {
  VIDEO_CONTENT_inspirational: VIDEO_CONTENT_inspirational.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_inspirational: SHOWCASE_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_inspirational: SHARING_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_experiential: VIDEO_CONTENT_experiential.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_experiential: SHOWCASE_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_experiential: SHARING_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_funny: VIDEO_CONTENT_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_funny: SHOWCASE_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_funny: SHARING_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  defaultInvite: defaultInvite
    .replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  DefaultMonthlyGiveawayInvite: monthlyGiveawayDefault
    .replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
};
