import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { SendInviteTimeoutCard } from './components/SendInviteTimeoutCard';
import { useSendInvitesTabStyles } from './SendInvitesTab.styles';

// import differenceInHours from 'date-fns/differenceInHours';
// import differenceInSeconds from 'date-fns/differenceInSeconds';
// import addHours from 'date-fns/addHours';
// import addSeconds from 'date-fns/addSeconds';
import { InvitationStats } from './components/InvitationStats';
import { useSendInvitesTab } from './useSendInvitesTab';
import {
  getActiveInvitations,
  getCampaignInvitations,
  getInvitesCounts,
  getInvitesQueueStats,
  getLastSendBatchStats,
  getQueuedInvitations,
  getUnsentInvitations,
  getUnsentInvitationsAPICall,
  sendInvitations,
} from '../../../../../../store/slices/campaignsSlice';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { PreparedInvites } from './components/PreparedInvites';
import { getWelcomeVideoType } from '../WelcomeVideoTab/WelcomeVideoTab';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { useVenueProperty } from '../../../../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../../../../api/models/venue';
import { setElementsActiveTabIndex } from '../../../../../../store/slices/uiSlice';
import { useInvitesQueueStats } from '../../useInvitesQueueStats';
import { getIsNullReward } from '../../../../../../services/utilities';

// export const SEND_INVITES_TIMEOUT_SECONDS = 3 * 24 * 60 * 60; // 3 days
export const SEND_INVITES_TIMEOUT_SECONDS_TEST = 10 * 60; // 10 min

const genericContinueText = 'Continue to XXX prepared invites';

export const SendInvitesTab: React.FC = () => {
  const styles = useSendInvitesTabStyles();

  const { unsentInvites, canNotSend, dateDifference, invitesToSend } = useInvitesQueueStats();

  const dispatch = useAppDispatch();

  const [isSendingInvites, setIsSendingInvites] = useState(false);
  const { currentCampaign, isLoading } = useTypedSelector((state) => state.campaigns);

  const showRewardTip = getIsNullReward(currentCampaign?.campaignType);
  const showInvitationTip = !currentCampaign?.invitationMsgConfig?.mediaUrl;

  const welcomeVideoType = getWelcomeVideoType(currentCampaign);
  const showWelcomeVideoTip = welcomeVideoType !== ElementsStrings.Custom;

  const showTips = showRewardTip || showInvitationTip || showWelcomeVideoTip;
  const [statsChecked, setStatsChecked] = useState(false);

  const navigateToSettingsTab = (tabIndex: number) => {
    dispatch(setElementsActiveTabIndex(tabIndex));
  };

  const data = useSendInvitesTab();

  const continueText = unsentInvites
    ? genericContinueText.replace('XXX', invitesToSend.toString())
    : '';

  // const isSendTimeoutPassed = data.lastSendDate
  //   ? differenceInSeconds(new Date(), data.lastSendDate) >= SEND_INVITES_TIMEOUT_SECONDS_TEST
  //   : true;

  const { value: inviteStatsViewed, update } = useVenueProperty<
    VenueProperties['dashboard.invite-stats-viewed']
  >({
    property: 'dashboard.invite-stats-viewed',
  });

  const getViewState = () => {
    // if (!isSendTimeoutPassed) return 'timeout';
    if (!(data.lastSendDate !== null) && !unsentInvites) return 'empty';
    if (
      (!inviteStatsViewed?.includes(currentCampaign?.id!) && data.lastSendDate !== null) ||
      (data.preparedInvites && !statsChecked && data.lastSendDate !== null)
    )
      return 'stats';
    return unsentInvites ? 'invites' : 'stats';
  };

  const viewState = getViewState();

  const sendPreparedInvites = async () => {
    if (!currentCampaign) return;
    setIsSendingInvites(true);

    try {
      const campaignId = currentCampaign.id;

      const res = await getUnsentInvitationsAPICall({
        campaignId,
        pageable: { page: 0, size: unsentInvites, sort: ['createdAt', 'asc'] },
      });

      const preparedInvites = res.items;

      await dispatch(
        sendInvitations({
          campaignId,
          invitationIds: preparedInvites.map((i) => i.id),
        }),
      );

      dispatch(getActiveInvitations({ campaignId }));
      dispatch(getUnsentInvitations({ campaignId, pageable: {} }));
      dispatch(getQueuedInvitations({ campaignId, pageable: {} }));
      dispatch(getLastSendBatchStats({ campaignId }));
      dispatch(getCampaignInvitations({ campaignId }));
      dispatch(getInvitesCounts({ campaignId }));
      dispatch(getInvitesQueueStats(campaignId));

      if (inviteStatsViewed) {
        update(inviteStatsViewed.filter((id) => id !== campaignId));
      }
    } finally {
      setIsSendingInvites(false);
    }
  };

  const setInvitesViewed = () => {
    if (inviteStatsViewed) {
      update([...inviteStatsViewed, currentCampaign?.id]);
    } else {
      update([currentCampaign?.id]);
    }
  };

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>
        {viewState === 'stats'
          ? 'Please review the performance of recent invites'
          : viewState === 'invites'
          ? 'Invites Are Ready To Send!'
          : viewState === 'empty'
          ? 'No Invites Prepared'
          : `Invite Your Customer`}
      </Typography>
      <Typography className={styles.subtitle}>
        {/* {viewState === 'timeout' && 'The prior group of sent invites is under evaluation.'}
        {viewState === 'invites' &&
          'Send up to 300 invites starting with the most recent transaction date.'}
        {viewState === 'stats' && 'Before you send more invites, please review the following...'} */}
        {viewState === 'empty' &&
          'Inviting your customers to create inspirational videos is the first step to showcasing clips on your website and Google Business profile.'}
      </Typography>

      {viewState === 'empty' ? (
        <div
          style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <LoadingButton
            variant={'contained'}
            sx={{ height: '48px', width: '262', fontSize: '16px' }}
            onClick={() => {
              navigateToSettingsTab(0);
            }}
          >
            {'Go To Add Your Customers'}
          </LoadingButton>
        </div>
      ) : (
        <></>
      )}

      {viewState === 'invites' && (
        <PreparedInvites
          preparedInvites={unsentInvites}
          availableCustomers={unsentInvites}
          onInvitesDelete={() => {
            navigateToSettingsTab(0);
          }}
        />
      )}

      {viewState === 'stats' && <InvitationStats />}

      {showTips && (
        <Box className={styles.tipsContainer}>
          <Typography className={styles.tipsTitle}>
            {viewState === 'stats'
              ? 'Want to boost the performance? We recommend the following:'
              : 'Tips to boost video clip creation:'}
          </Typography>
          <ul className={styles.tipsList}>
            {showRewardTip && (
              <li className={styles.tipListItem}>
                Customers are more likely to upload when an{' '}
                <Button
                  className={styles.tipListItemLink}
                  onClick={() => {
                    navigateToSettingsTab(3);
                  }}
                >
                  Reward
                </Button>{' '}
                is offered.
              </li>
            )}
            {showInvitationTip && (
              <li className={styles.tipListItem}>
                Include a logo or familiar picture in your{' '}
                <Button
                  className={styles.tipListItemLink}
                  onClick={() => {
                    navigateToSettingsTab(1);
                  }}
                >
                  Invitation Text Message
                </Button>
              </li>
            )}
            {showWelcomeVideoTip && (
              <li className={styles.tipListItem}>
                Ask for the type of clips you want with a custom{' '}
                <Button
                  className={styles.tipListItemLink}
                  onClick={() => {
                    navigateToSettingsTab(2);
                  }}
                >
                  Welcome video
                </Button>
              </li>
            )}
          </ul>
        </Box>
      )}

      <Box className={styles.buttonContainer}>
        {canNotSend && data.lastSendDate && <SendInviteTimeoutCard nextSendDate={dateDifference} />}
        {!canNotSend && unsentInvites && viewState !== 'invites' ? (
          <LoadingButton
            fullWidth
            variant={unsentInvites ? 'outlined' : 'contained'}
            sx={{ height: '72px', fontSize: '22px' }}
            onClick={() => {
              if (!unsentInvites) {
                navigateToSettingsTab(0);
              } else {
                setStatsChecked(true);
              }
              setInvitesViewed();
            }}
          >
            {unsentInvites ? continueText : 'Add Your Customers Now'}
          </LoadingButton>
        ) : (
          <></>
        )}

        {!canNotSend && viewState === 'invites' && (
          <LoadingButton
            loading={isSendingInvites || isLoading}
            loadingIndicator={<CircularProgress />}
            fullWidth
            variant="contained"
            sx={{ height: '72px', fontSize: '22px' }}
            onClick={sendPreparedInvites}
            disabled={!unsentInvites}
          >
            Send {unsentInvites.toString()} Invites Now
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
